<template>
  <div id="join-us-page">
    <section class="container-fluid py-2 page-title-section">
      <h4 class="x-title">REGISTRATE PARA VENDER EN ANLESA</h4>
    </section>

    <section class="banner-section">
      <img class="img-desktop" src="">
      <img class="img-mobile" src="">
    </section>
<!--
    <section class="container px-sm-0 mb-4 text-center text-section">
      <h5 class="mb-1 f-w-600">Bienvenido emprendedor</h5>
      <p>
        Forma parte del nuevo MARKET PLACE Mexicano, en donde puedes publicar productos ilimitados y te apoyaremos para llevar tu marca al siguiente nivel.
      </p>
      <p class="mt-2">
        Completa este preregistro y realiza el pago de gastos administrativos de manera segura con débito o crédito. Nuestro personal evaluará tu proyecto e industria y recibirás una respuesta en los siguientes 3 días hábiles.
      </p>
    </section> -->

    <!-- <section class="container px-sm-0 mb-4 text-center text-section">
      <h5 class="mb-1 f-w-600">Características de nuestra plataforma</h5>

      <div class="row box-elements">
        <div class="col-sm-6 col-lg-3 col-el" v-for="(i, irInx) in infoExtra" :key="'irInx-'+irInx">
          <div class="box">
            <h5>{{ i }}</h5>
          </div>
        </div>
      </div>
    </section> -->

    <!-- Form -->
    <b-form @submit.prevent="onSubmit($event.target)" >
      <div class="container pb-1 box-step">
        <h4 class="b-title"> <span class="circle-num"><i>1</i></span> Cuéntanos sobre tí</h4>

        <div class="row" >
          <b-form-group class="cus-f-group-1 col-md-12" label="Nombre completo *">
            <b-form-input type="text" v-model="form.name" required placeholder=""></b-form-input>
          </b-form-group>

          <b-form-group class="cus-f-group-1 col-md-6" label="Correo electrónico *">
            <b-form-input type="email" v-model="form.email" required placeholder=""></b-form-input>
          </b-form-group>

          <b-form-group class="cus-f-group-1 col-md-6" label="Movil (Whatsapp) *">
            <b-form-input type="text" v-model="form.phone" required minlength="10" maxlength="10"></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="container pb-1 box-step">
        <h4 class="b-title"> <span class="circle-num"><i>2</i></span> Sobre tu negocio</h4>

        <div class="row" >
          <b-form-group class="cus-f-group-1 col-md-12" label="Agrega tu logo en formato JPG o PNG no mayor a 5 MB">
            <div class="fileinput fileinput-new" data-provides="fileinput">
              <!--div class="fileinput-new thumbnail" style="width: 200px; height: 150px;" data-trigger="fileinput">
                <img src="http://placehold.it/200x150?text=Imagen" alt="...">
              </div-->
              <div class="fileinput-preview fileinput-exists thumbnail"></div>
              <div>
                <span class="btn btn-white btn-file fileinput-con">
                  <input type="file" accept="image/*," name="logo" id="lgo">
                </span>
              </div>
            </div>
          </b-form-group>

          <b-form-group class="cus-f-group-1 col-md-6" label="Nombre de tu marca *">
            <b-form-input type="text" v-model="form.brand" required placeholder=""></b-form-input>
          </b-form-group>

          <b-form-group class="cus-f-group-1 col-md-6" label="Categoría *">
            <b-form-select required v-model="form.category_id">
              <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
              <b-form-select-option v-for="(category, indx) in categoriesOpcs" :value="category.id" :key="indx">{{category.name}}</b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group class="cus-f-group-1 col-md-6" label="Subcategoria *" v-if="subcategoriesOpcs.length > 0">
            <b-form-select v-model="form.subcategory_id">
              <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
              <b-form-select-option v-for="(subcategory, indx) in subcategoriesOpcs" :value="subcategory.id" :key="indx">{{subcategory.name}}</b-form-select-option>
            </b-form-select>
          </b-form-group>

          <!-- <div class="col-sm-6 col-form">
            <b-form-group
              label="Categoría:"
              label-for="i-category">
                <b-form-select
                  id="i-category"
                  v-model="form.category_id"
                  size="sm"
                  required>
                  <option :value="null" selected disabled >Seleccione una opción</option>
                  <option v-for="(category, indx) in categoriesOpcs" :value="category.id" :key="indx">{{category.name}}</option>
                </b-form-select>
            </b-form-group>
          </div> -->

          <b-form-group class="cus-f-group-1 col-md-12" label="Cuéntanos sobre tu proyecto y emprendimiento *">
            <b-form-textarea
              id="textarea"
              v-model="form.comments"
              placeholder=""
              rows="5"
              max-rows="5"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>

      <div class="container box-step">
        <h4 class="b-title"> <span class="circle-num"><i>3</i></span> Tu documentación</h4>

        <div class="row">
          <b-form-group class="cus-f-group-1 no-label col-md-12" v-slot="{ ariaDescribedby }">
            <b-form-radio v-model="form.person_type" :aria-describedby="ariaDescribedby" name="p-person_type" value="fisica">Persona física</b-form-radio>
            <b-form-radio v-model="form.person_type" :aria-describedby="ariaDescribedby" name="p-person_type" value="moral">Persona moral</b-form-radio>
          </b-form-group>

          <b-form-group class="cus-f-group-1 col-md-6" label="Cédula físcal">
            <div class="fileinput fileinput fileinput-new" data-provides="fileinput">
              <div class="fileinput-preview fileinput-exists thumbnail"></div>
              <div>
                <span class="btn btn-white btn-file fileinput-con">
                  <input type="file" accept=".pdf,image/*" name="cedulafiscal" id="cedulafiscal">
                </span>
              </div>
            </div>
          </b-form-group>

          <b-form-group class="cus-f-group-1 col-md-6" label="Registro IMPI">
            <div class="fileinput fileinput-new" data-provides="fileinput">
              <div class="fileinput-preview fileinput-exists thumbnail" style="max-width: 400px; max-height: 300px">
              </div>
              <div>
                <span class="btn btn-white btn-file fileinput-con">
                  <input type="file" accept=".pdf,image/*" name="registro_impi" id="registro_impi">
                </span>
              </div>
            </div>
          </b-form-group>
        </div>
      </div>

      <div class="container">
        <div class="box-note">
          Costo de afiliación, gratis para una visita de explicación, y posteriormente se te cobrara una anualidad fija.
        </div>
      </div>

      <div class="container box-step">
        <h4 class="b-title"> <span class="circle-num"><i>4</i></span> Términos y condiciones de uso</h4>

        <div class="row" >
          <!-- <div class="col-12 mb-1">
            <b-form-checkbox class="cus-f-checkbox" v-model="form.convenio" name="c-convenio" :value="true" :unchecked-value="false" required>
              Acepto haber leído, comprendido y autorizado plenamente el <router-link target="_blank" to="/convenio-anlesa">Convenio entre Anlesa y Creadores</router-link>.
            </b-form-checkbox>
          </div>
          <div class="col-12 mb-1">
            <b-form-checkbox class="cus-f-checkbox" v-model="form.declaracion" name="c-declaracion" :value="true" :unchecked-value="false" required>
              Acepto haber leído, comprendido y autorizado plenamente la <router-link target="_blank" to="/declaracion-de-privacidad-confidencialidad">Declaración de privacidad y confidencialidad de la información de México Limited</router-link>.
            </b-form-checkbox>
          </div>
          <div class="col-12 mb-1">
            <b-form-checkbox class="cus-f-checkbox" v-model="form.terminos" name="c-terminos" :value="true" :unchecked-value="false" required>
              Acepto haber leído, comprendido y autorizado plenamente los <router-link target="_blank" to="/terminos-y-condiciones-marketplace">Términos y Condiciones del Marketplace de México Limited</router-link>.
            </b-form-checkbox>
          </div>

          <div class="col-12 mb-1">
            <b-form-checkbox class="cus-f-checkbox" v-model="form.afiliado" name="c-afiliado" :value="true" :unchecked-value="false" required>
              Acepto haber leído los términos y condiciones & aviso de privacidad de afiliado.
            </b-form-checkbox>
          </div> -->

          <div class="col-12 mb-1">
            <b-form-checkbox class="cus-f-checkbox" v-model="form.terminos" name="c-uso" :value="true" :unchecked-value="false" required>
              Acepto haber leído los <a style="cursor: pointer; text-decoration: underline !important;" @click="openTCModal()">términos y condiciones & aviso de privacidad de uso</a>.
            </b-form-checkbox>
          </div>

          <div class="col-12 mt-3">
            <b-button type="submit" class="btn-s1 b-pink" id="btnsubmit" style="background-color: #ee217f;">Completar registro</b-button>

          </div>
        </div>
      </div>
    </b-form>
    <!--  -->

    <b-modal modal-class="modal-info" size="lg" ref="modal-info" title="" centered hide-footer>
      <h3 class="tyc-title">Términos y Condiciones de ANLESA</h3>

      <p>
        <strong>Fecha de última actualización:</strong> 14 de Octubre de 2024
      </p>

      <br />
      <p>
        Bienvenido a ANLESA. Al acceder o utilizar nuestro sitio web y los servicios proporcionados por ANLESA ("nosotros", "nuestro" o "la plataforma"), usted acepta cumplir y estar sujeto a los siguientes Términos y Condiciones. Lea estos términos cuidadosamente antes de usar el servicio.
      </p>

      <br />
      <h5>Aceptación de los Términos</h5>
      <p>
        Al utilizar la plataforma ANLESA, usted acepta y está de acuerdo con estos Términos y Condiciones. Si no está de acuerdo, no debe utilizar los servicios de ANLESA.
      </p>

      <br />
      <h5>Descripción del Servicio</h5>
      <p>
        ANLESA es una plataforma de marketplace que permite a los usuarios comprar y vender productos. Nosotros proporcionamos el espacio virtual para el intercambio pero no somos parte de las transacciones entre los compradores y vendedores.
      </p>

      <br />
      <h5>Elegibilidad</h5>
      <p>
        Para utilizar nuestros servicios, debe ser mayor de edad y tener la capacidad legal para celebrar contratos vinculantes.
      </p>

      <br />
      <h5>Cuentas de Usuario</h5>
      <p>
        Para vender o comprar en ANLESA, deberá crear una cuenta. Usted es responsable de mantener la confidencialidad de su cuenta y contraseña, y acepta la responsabilidad de todas las actividades que ocurran bajo su cuenta.
      </p>

      <br />
      <h5>Listado de Productos</h5>
      <p>
        Los vendedores deben garantizar que los productos listados cumplen con todas las leyes y regulaciones aplicables. ANLESA se reserva el derecho de remover cualquier listado que no cumpla con nuestras políticas o sea reportado como inapropiado.
      </p>

      <br />
      <h5>Tarifas y Pagos</h5>
      <p>
        ANLESA cobra una tarifa de servicio por cada transacción completada en la plataforma. Las tarifas son detalladas en nuestra sección de tarifas y pueden ser modificadas con previo aviso al usuario.
      </p>

      <br />
      <h5>Políticas de Devoluciones y Reembolsos</h5>
      <p>
        Las políticas de devolución y reembolso dependen de cada vendedor, pero deben ser claramente especificadas en cada listado de producto. Los compradores deberán contactar con los vendedores directamente para cualquiera de estas solicitudes.
      </p>

      <br />
      <h5>Limitación de Responsabilidad</h5>
      <p>
        ANLESA no será responsable de ningún daño indirecto, incidental, especial, consecuente o punitivo, ni de ninguna pérdida de beneficios o ingresos, incurridos directa o indirectamente.
      </p>

      <br />
      <h5>Propiedad Intelectual</h5>
      <p>
        Todo el contenido proporcionado en la plataforma es propiedad de ANLESA o sus licenciantes y está protegido por leyes de propiedad intelectual.
      </p>

      <br />
      <h5>Modificación de los Términos</h5>
      <p>
        Nos reservamos el derecho de modificar estos Términos y Condiciones en cualquier momento. Notificaremos a los usuarios de cualquier cambio sustancial en los términos.
      </p>

      <br />
      <h5>Contacto</h5>
      <p>
        Si tiene alguna pregunta sobre estos Términos y Condiciones, por favor contacte con nosotros en  ayuda@anlesa.com
      </p>
    </b-modal>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modalAlert">
      <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
      <div v-html="modal.msg"></div>
      <b-button v-if="modal.icon == 'success'" @click="toHome" class="mt-3 btn btn-info btn-s1 b-pink" style="width: 90%;">Continuar</b-button>
    </sweet-modal>

  </div>
</template>

<script type="text/javascript">
export default {
  data(){
    return{
      infoExtra: [
        'Promoción continua',
        'Exposición nacional e internacional',
        'Asesoría gratuita',
        'Cargo único de inscripción',
        'Monitoreo 24 horas',
        'Soporte técnico personalizado',
        'Comisión del 20% por venta',
        'Ventas encriptadas y protegidas por STRIPE®',
      ],

      form:{
        name:null,
        email:null,
        phone:null,

        brand:null,
        category_id:null,
        subcategory_id:null,
        comments:null,

        person_type:null,

        venta:null,
        afiliado:null,
        uso:null,
        payment_intent_id:null,

        convenio:false,
        declaracion:false,
        terminos:false,
      },
      categoriesOpcs:[],
      subcategoriesOpcs:[],
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },
  watch:{
    'form.category_id':function(){
        this.getSubcategories();
    }
  },
  methods: {
    openTCModal() {
      this.$refs['modal-info'].show();
    },

    onSubmit(form){

      this.$root.isLoading = true;
      var data=tools.params(form,this.form);
      axios.post(tools.url("/api/registerFront"),data).then((response)=>{
            this.form = {
              name:null,
              email:null,
              phone:null,

              brand:null,
              category_id:null,
              comments:null,

              person_type:null,

              venta:null,
              afiliado:null,
              uso:null,
              payment_intent_id:null
            };

            this.modal.block = false;
            this.modal.icon = 'success';
            this.modal.msg = `
              <div style="font-size: 0.95rem;">
                <h4>Gracias por tu pre-registro</h4>
                <p>El folio con el que podrás dar seguimiento es el numero:<br /><b class="h4 f-w-600">`+response.data.id+`</b></p>
                <p class="mt-2">Para cualquier duda o comentario escribenos a <a href="mailto:ayuda@anlesa.com">ayuda@anlesa.com</a> </p>

              </div>
              `;

              this.$root.isLoading = false;
              this.$refs.modalAlert.open();

      }).catch((error)=>{
            this.modal.block = false;
            this.modal.icon = 'error';
            this.modal.msg = 'Ocurrio un error al guardar la información';
            if(error.response.data.status == 'emailduplicate'){
                this.modal.icon = 'warning';
                this.modal.msg = 'El correo ingresado ya se encuentra en nuestra base de datos. Probablemente ya te registraste anteriormente.<br>Para cualquier duda escribenos a <a href="mailto:ayuda@anlesa.com">ayuda@anlesa.com</a>';
            }
            this.$root.isLoading = false;
            this.$refs.modalAlert.open();

      });

    },
    getCategories(){
      axios.get(tools.url("/api/categories")).then((response)=>{
        this.categoriesOpcs = response.data;

      }).catch((error)=>{
      });
    },
    getSubcategories(){
      var data = [];
      data.push(this.form.category_id);

      axios.post(tools.url("/api/subcategories"),{categories:data}).then((response)=>{
        this.subcategoriesOpcs = response.data;

      }).catch((error)=>{
      });
    },

    toHome(){
      this.$router.push('/');
      location.reload();
    },


  },
  mounted() {
    this.getCategories();
  }
}
</script>
<style>

</style>
