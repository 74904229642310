/*
 *
 * Estas librerias estan presentes tanto en la website como en el dashboard
 *
 */

//Librerias necesarias
import VeeValidate, { Validator } from 'vee-validate';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';

import loader from "vue-ui-preloader";
import es from 'vee-validate/dist/locale/es';
import vSelect from 'vue-select';
import vueTopprogress from 'vue-top-progress';
// import '@fortawesome/fontawesome-free/js/all.js';
import SweetModal from 'sweet-modal-vue/src/plugin.js';

//Funcion para añadirlas a Vue
function fire(Vue){
	//Vee-validate
	Validator.localize('es', es);
	Vue.use(VeeValidate,{locale:"es"});

	//Vue-select
	Vue.component('v-select', vSelect);
	//Loading bar
	Vue.use(vueTopprogress);
	
	Vue.use(SweetModal);

	Vue.component('ValidationObserver', ValidationObserver);
	Vue.component('ValidationProvider', ValidationProvider);
	Vue.use(loader);
}



// Install by default if using the script tag
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(plugin)
}

export default fire;
