<template>
	<footer class="main">				
		&copy; {{ anio }} 
	</footer>
</template>

<script type="text/javascript">
	export default {
		data(){
            return {
              anio:null
			}
		},
        mounted() {
            const d = new Date();
			this.anio =  d.getFullYear();
        }
    }
</script>