import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
	    { path: '/', component: require(page+'user-access/join.vue').default, meta:{title:"Afíliate"}},
     /* { path: '/landing', component: require(page+'landing/index.vue').default },

      { path: '/nosotros', component: require(page+'about-us/index.vue').default, meta:{title:"Nosotros"}},
      { path: '/contacto', component: require(page+'contact/index.vue').default, meta:{title:"Contacto"}},
      { path: '/terminos-y-condiciones', component: require(page+'text-pages/terminos.vue').default, meta:{title:"Términos y condiciones"}},
      { path: '/preguntas-frecuentes-clientes', component: require(page+'text-pages/faqs-clientes.vue').default, meta:{title:"Preguntas frecuentes de los Clientes"}},
      { path: '/preguntas-frecuentes-afiliados', component: require(page+'text-pages/faqs-usuarios.vue').default, meta:{title:"Preguntas frecuentes de los Afiliados"}},
      { path: '/aviso-de-privacidad', component: require(page+'text-pages/aviso.vue').default, meta:{title:"Aviso de privacidad"}},



      { path: '/declaracion-de-privacidad-confidencialidad', component: require(page+'text-pages/declaracion-de-privacidad.vue').default, meta:{title:"Declaración de privacidad y confidencialidad de la información de Anlesa"}},
      { path: '/politica-de-envio-devoluciones', component: require(page+'text-pages/politica-de-envio-devoluciones.vue').default, meta:{title:"Politica de envío y devoluciones Anlesa"}},
      { path: '/terminos-y-condiciones-marketplace', component: require(page+'text-pages/terminos-marketplace.vue').default, meta:{title:"Términos y condiciones del Marketplace de Anlesa"}},
      { path: '/convenio-anlesa', component: require(page+'text-pages/convenio-anlesa.vue').default, meta:{title:"Convenio Anlesa y Creadores"}},
      { path: '/terminos-y-condiciones-de-uso', component: require(page+'text-pages/terminos-de-uso.vue').default, meta:{title:"Términos y condiciones de uso de Anlesa"}},
      


      { path: '/comunicados', component: require(page+'blog/index.vue').default, meta:{title:"Comunicados"}},
      { path: '/comunicados/:id', component: require(page+'blog/detail.vue').default, meta:{title:"Comunicado"}},

      { path: '/login', component: require(page+'user-access/login.vue').default, meta:{title:"Iniciar sesión"}},
      { path: '/recuperar-contrasena', component: require(page+'user-access/reset-password.vue').default, meta:{title:"Recuperar contraseña"}},
      { path: '/registrarse', component: require(page+'user-access/register.vue').default, meta:{title:"Registrarse"}},
      { path: '/pendiente-pago/:id', component: require(page+'user-access/pendiente-pago.vue').default, meta:{title:"Pendiente de pago"}},
      { path: '/asociarse', component: require(page+'user-access/join.vue').default, meta:{title:"Afíliate"}},
      { path: '/asociarse-promo', component: require(page+'user-access/join-promo.vue').default, meta:{title:"Afíliate"}},

      { path: '/categoria/:id', name: 'categoryPage', component: require(page+'products/categoriesSubcategories-v2.vue').default, meta:{title:"Categorias"}},
      { path: '/categoria/:id/:subid', component: require(page+'products/categoriesSubcategories-v2.vue').default, meta:{title:"Categoria"}},
      // RUTA NO USADA YA // { path: '/categoria/productos/:id', component: require(page+'products/categoriesproducts.vue').default, meta:{title:"Categorias"}},

      { path: '/subcategoria/:id', component: require(page+'products/subcategories.vue').default, meta:{title:"Subcategorias"}},

      { path: '/busqueda', component: require(page+'products/search.vue').default, meta:{title:"Búsqueda"}},
      { path: '/producto/:id', component: require(page+'products/description.vue').default, meta:{title:"Producto"}},
      { path: '/ofertas', component: require(page+'products/offers.vue').default, meta:{title:"Ofertas"}},

      { path: '/cart/:step?', component: require(page+'carrito/index.vue').default, meta:{title:"Mi carrito de compras"}},

      { path: '/emprendedor/:id', component: require(page+'products/entrepreneurs.vue').default, meta:{title:"Emprendedor"}},

      { path: '/devoluciones', component: require(page+'contact/devoluciones.vue').default, meta:{title:"Devoluciones"}},
       { path: '/quejas-sugerencias', component: require(page+'contact/quejas.vue').default, meta:{title:"Quejas y Sugerencias"}},


      { path: '/usuario', component: require(page+'usuario-cuenta/index.vue').default, meta:{ title: 'Mi datos' },
  			children: [
  			{
  				path: '/',
  				component: require(page+'usuario-cuenta/mis-datos.vue').default,
  				meta:{ title: 'Mis datos' }
  			},
  			{
  				path: 'contrasena',
  				component: require(page+'usuario-cuenta/contrasena.vue').default,
  				meta:{ title: 'Cambiar contraseña' }
  			},
        {
            path: 'historial-compras',
            component: require(page+'usuario-cuenta/historial-compras.vue').default,
            meta:{ title: 'Mis pedidos' }
        },
        {
            path: 'pedido/:id',
            component: require(page+'usuario-cuenta/orderdetails.vue').default,
            meta:{ title: 'Mis pedidos' }
        },
  	  	]
  		}*/
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "Anlesa", filter: (title)=>{ return title+" - Anlesa"; } }
);

// export {routes};
export default MyRouter;
